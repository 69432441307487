import { createContext, useCallback, useMemo, useState } from "react";
import ProfileService from "services/ProfileService";

const defaultContext = {
  loading: false,
  getProfile: () => {},
};

export const AppContext = createContext(defaultContext);

export const AppProvider = ({ children }) => {
  const [profile, setProfile] = useState();
  const [links, setLinks] = useState([]);
  const [loading, setLoading] = useState(defaultContext.loading);
  const [error, setError] = useState();

  const getProfile = useCallback(async (id) => {
    setLoading(true);
    try {
      const res = await ProfileService.get(id);
      setProfile(res.data);
    } catch (err) {
      console.error(err);
      setError("");
      window.open("/not-found", "_self");
    }
    setLoading(false);
  }, []);

  const getLinks = useCallback(
    async (id) => {
      setLoading(true);
      try {
        const res = await ProfileService.getLinks(id);
        const sortedList = [];
        const unSortedList = res.data.results || [];

        (profile.orderedLinks || []).forEach((a) => {
          const index = unSortedList.findIndex((element) => element.id === a);
          if (index >= 0) {
            sortedList.push(unSortedList[index]);
            unSortedList.splice(index, 1);
          }
        });

        sortedList.push(...unSortedList);
        setLinks(sortedList);
      } catch (err) {
        console.error(err);
        setError("");
      }
      setLoading(false);
    },
    [profile]
  );

  const contextValues = useMemo(
    () => ({
      profile,
      links,
      error,
      loading,
      getProfile,
      getLinks,
    }),
    [profile, links, error, loading, getProfile, getLinks]
  );

  return (
    <AppContext.Provider value={contextValues}>{children}</AppContext.Provider>
  );
};
