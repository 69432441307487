import React from "react";

const DataCard = ({ value, image, title, baseUrl }) => {
  return (
    <a href={`${baseUrl}${value}`} target="_blank" rel="noreferrer">
      <div
        className="d-flex align-items-center  p-2"
        style={{ marginBottom: "10px" }}
      >
        <img
          src={image}
          alt="link"
          style={{ width: "20px", height: "20px", marginRight: "10px" }}
        />

        <div className="d-flex flex-column justify-content-start align-items-start">
          <div
            style={{
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
              width: "300px",
              textAlign: "start",
            }}
          >
            {value}
          </div>
          <div style={{ color: "#C0A17B" }}>{title}</div>
        </div>
      </div>
    </a>
  );
};

export default DataCard;
