import React from "react";

const LinkCard = ({ link }) => {
  const url = link.value.includes("http")
    ? link.value
    : `https://${link.value}`;
  return (
    <a href={url} target="_blank" rel="noreferrer">
      <div
        className="d-flex align-items-center p-2"
        style={{ marginBottom: "10px" }}
      >
        <img
          src="./assets/icWeb.png"
          alt="link"
          style={{ width: "20px", height: "20px", marginRight: "10px" }}
        />

        <div className="d-flex flex-column justify-content-start align-items-start">
          <div
            style={{
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
              width: "250px",
              textAlign: "start",
            }}
          >
            {link.value}
          </div>
          <div
            style={{
              color: "#C0A17B",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
              width: "250px",
              textAlign: "start",
            }}
          >
            {link.title}
          </div>
        </div>
      </div>
    </a>
  );
};

export default LinkCard;
