import { useAppContext } from "hooks/useAppContext";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import "./profile.css";
import DataList from "components/profile/DataList";
import LinksList from "components/profile/LinksList";
import { useParams, useLocation } from "react-router-dom";

const Profile = ({ match }) => {
  const { id } = useParams();
  const location = useLocation();

  // ** Hooks
  const { profile, links, getProfile, getLinks } = useAppContext();

  useEffect(() => {
    getProfile(`${id}${location.search || ""}`);
  }, [id, getProfile, location.search]);

  useEffect(() => {
    if (profile) {
      getLinks(profile.id);
    }
  }, [getLinks, profile]);

  const connectHandler = () => {
    var urlString =
      "https://api.blacklink.cc/v1/profiles/contact-card/" + profile.id;
    window.open(urlString, "_self");
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>BLACKLINK</title>
      </Helmet>
      {profile && (
        <section>
          <div className="m-auto col-md-4 rounded-0 px-0 my-0 text-center">
            <div
              style={{
                background: profile.themeColor
                  ? `linear-gradient(${profile.themeColor},transparent)`
                  : "transparent",
              }}
            >
              <div
                className="profile-cover"
                style={{
                  backgroundImage: `url(${
                    profile.cover && profile.cover !== ""
                      ? `${process.env.REACT_APP_API_URL}/v1/${profile.cover}`
                      : `${process.env.PUBLIC_URL}/assets/cover_placeholder.png`
                  })`,
                }}
              >
                <img
                  src={
                    profile.image && profile.image !== ""
                      ? `${process.env.REACT_APP_API_URL}/v1/${profile.image}`
                      : `${process.env.PUBLIC_URL}/assets/cover_placeholder.png`
                  }
                  className="profile-image rounded-circle img-fluid"
                  alt=""
                />
              </div>

              <div className="p-4" style={{ marginTop: "50px" }}>
                <h4 style={{ color: "#fff" }}>
                  {profile.firstName} {profile.lastName}
                </h4>
                <span className="d-block">{profile.jobTitle}</span>
                <span className="d-block mb-2">{profile.company}</span>
                <button
                  onClick={connectHandler}
                  className="btn btn-primary btn-sm my-2"
                  style={{
                    backgroundColor: `#C0A17B`,
                    borderRadius: "10px",
                  }}
                >
                  Save to Phone
                </button>
              </div>
            </div>

            <DataList
              values={profile.phones}
              image={"./assets/icPhone.png"}
              baseUrl={"tel://"}
            />
            <DataList
              values={profile.emails}
              image={"./assets/icEmail.png"}
              baseUrl={"mailto://"}
            />
            <div className="row data-list">
              <div className="col-12">
                <div
                  className="d-flex align-items-center  p-2"
                  style={{ marginBottom: "10px" }}
                >
                  <img
                    src="./assets/icPin.png"
                    alt="link"
                    style={{
                      width: "20px",
                      height: "20px",
                      marginRight: "10px",
                    }}
                  />

                  <div className="d-flex flex-column justify-content-start align-items-start">
                    <div
                      style={{
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        width: "300px",
                        textAlign: "start",
                      }}
                    >
                      {profile.workAddress}
                    </div>
                    <div style={{ color: "#C0A17B" }}>Work Address</div>
                  </div>
                </div>
              </div>
            </div>
            <LinksList links={links} />
          </div>
        </section>
      )}
    </>
  );
};

export default Profile;
