import React from "react";
import DataCard from "./DataCard";

const DataList = ({ values, image, baseUrl }) => {
  return (
    <div className="">
      {values && values.length >= 1 && (
        <div className="row data-list">
          {values.map((data, key) => {
            return (
              <div className="col-12" key={key}>
                <DataCard
                  value={data.value}
                  title={data.title}
                  image={image}
                  baseUrl={baseUrl}
                />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default DataList;
